<template>
  <b-modal id="transport-modal" centered :title="title" @ok="handleOk">
    <b-overlay :show="$apollo.loading || loadingSave" rounded="sm">
      <b-form ref="formTransport" @submit.prevent="saveTransport">
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group row mb-2">
              <label
                for="input-transportNumber"
                class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                >เลข Tracking</label
              >
              <div class="col-lg-9 col-sm-4 col-12">
                <b-form-input
                  id="input-transportNumber"
                  v-model="systemTransport.transportNumber"
                  required
                  @blur="onBlur"
                  @keydown="onKeyDown"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row mb-2" v-if="searchUserList">
              <label
                class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                >ผู้ใช้งาน</label
              >
              <div class="col-lg-9 col-sm-4 col-12">
                <v-select
                  searchable
                  :filterable="false"
                  placeholder="กรุณารหัสผู้ใช้"
                  required
                  :options="searchUserList.records"
                  label="username"
                  @search="searchUser"
                  v-model="systemTransport.user"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!systemTransport.user"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label
                for="input-ship_by"
                class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                >ประเภทสินค้า</label
              >
              <div class="col-lg-9 col-sm-4 col-12">
                <b-form-select
                  v-model="systemTransport.userLevelPrice"
                  :state="validateStateTransport('userLevelPrice')"
                  required
                  :options="
                    systemUserLevel &&
                      (systemTransport.ship_by === 'ek'
                        ? systemUserLevel.shippingEk
                        : systemUserLevel.shippingShip)
                  "
                ></b-form-select>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label
                class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                >ตีลังไม้</label
              >
              <div class="col-lg-9 col-sm-4 col-12">
                <span class="switch switch-sm mt-1">
                  <label class="d-flex align-items-center">
                    <input
                      type="checkbox"
                      v-model="systemTransport.guarantee"
                    />
                    <span></span>
                  </label>
                </span>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label
                class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                >ค่าตีลัง</label
              >
              <div class="col-lg-9 col-sm-4 col-12">
                <b-form-input
                  v-model="systemTransport.price_wooden_box"
                  type="number"
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label
                class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                >จำนวน</label
              >
              <div class="col-lg-9 col-sm-4 col-12">
                <b-form-input
                  v-model="systemTransport.amount"
                  type="number"
                  required
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label
                class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                >น้ำหนัก</label
              >
              <div class="col-lg-9 col-sm-4 col-12">
                <b-form-input
                  v-model="systemTransport.size.weight"
                  type="number"
                  required
                  placeholder=""
                ></b-form-input>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label
                class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                >ขนาดพัสดุ (กxยxส)</label
              >
              <div class="col-lg-9 col-sm-4 col-12">
                <div class="d-flex" style="gap: 8px">
                  <b-form-input
                    v-model="systemTransport.size.width"
                    type="number"
                    placeholder="กว้าง"
                  ></b-form-input>
                  <b-form-input
                    v-model="systemTransport.size.long"
                    type="number"
                    placeholder="ยาว"
                  ></b-form-input>
                  <b-form-input
                    v-model="systemTransport.size.height"
                    type="number"
                    placeholder="สูง"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="form-group row mb-2">
              <label
                class="col-form-label fw-bolder text-sm-right col-lg-3 col-sm-4 col-12"
                >ราคา</label
              >
              <div class="col-lg-9 col-sm-4 col-12 d-flex align-items-center">
                <div v-if="systemTransport.trackingPrice">
                  {{
                    systemTransport.trackingPrice.priceName === "kg"
                      ? "กิโล : "
                      : "ขนาด : "
                  }}
                  {{ systemTransport.trackingPrice.priceValue }}
                </div>
              </div>
            </div>
            <div class="form-group row mb-2">
              <b-form-group
                label-cols="12"
                label-cols-sm="4"
                label-cols-lg="3"
                label-align="right"
                content-cols="8"
                content-cols-sm="9"
                content-cols-lg="9"
                content-cols-xl="9"
                label="รูปภาพสินค้า"
                label-for="input-category"
                label-class="fw-bolder"
                class="mb-0"
              >
                <b-overlay :show="loadingImageList">
                  <vue-upload-multiple-image
                    @upload-success="uploadImageSuccess"
                    @before-remove="beforeRemove"
                    dragText="ลากรูปภาพ (หลายภาพ)"
                    browseText="(หรือ) เลือก"
                    primaryText="ค่าเริ่มต้น"
                    :showPrimary="false"
                    markIsPrimaryText="ตั้งเป็นค่าเริ่มต้น"
                    popupText="ภาพนี้จะแสดงเป็นค่าเริ่มต้น"
                    dropText="วางไฟล์ของคุณที่นี่..."
                    :data-images="systemTransport.file_tracking"
                  ></vue-upload-multiple-image>
                </b-overlay>
              </b-form-group>
            </div>
          </div>
        </div>
      </b-form>
    </b-overlay>
  </b-modal>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import VSelect from "vue-select";
import debounce from "debounce";
import axios from "axios";
import bigDecimal from "js-big-decimal";
import VueUploadMultipleImage from "vue-upload-multiple-image";
import { API_URL, calculateCube, imageUrlApi, IMAGE_URL } from "@/config";
import { SEARCH_USERS } from "@/graphql/admin/user";
import { GET_USER_LEVEL } from "@/graphql/admin/user_level";
import { ADD_LOT_TRACKING } from "@/graphql/admin/lot";

export default {
  props: [
    "title",
    "onComplete",
    "lotId",
    "transport",
    "dialog",
    "onClose",
    "onBlur"
  ],
  mixins: [validationMixin],
  components: {
    VSelect,
    VueUploadMultipleImage
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      nameState: null,
      messageError: "",
      loadingSave: false,
      loadingImageList: false,
      search: "",
      systemTransport: {
        transportNumber: "",
        user: null,
        amount: 0,
        ship_by: "ek",
        remark: "",
        guarantee: false,
        userLevelPrice: "",
        size: {
          weight: 0,
          width: 0,
          long: 0,
          height: 0
        },
        file_tracking: [],
        timeline: {},
        trackingPrice: {}
      }
    };
  },
  validations: {
    systemTransport: {
      user: {
        userId: {
          required
        }
      },
      transportNumber: {
        required
      },
      amount: {
        required
      },
      ship_by: {
        required
      },
      lotId: {},
      remark: {},
      guarantee: {},
      price_wooden_box: {},
      userLevelPrice: {
        required
      },
      file_tracking: {},
      size: {},
      timeline: {
        send_to_warehouse_china: {},
        send_out_china: {},
        send_to_warehouse_th: {}
      }
    }
  },
  methods: {
    imageUrlApi,
    onKeyDown(e) {
      if (e.keyCode === 13 && this.onBlur) {
        this.onBlur(e);
      }
    },
    validateStateTransport(name) {
      const { $dirty, $error } = this.$v.systemTransport[name];
      return $dirty ? !$error : null;
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.saveTransport();
    },
    async saveTransport() {
      this.$v.systemTransport.$touch();
      const userId = this.$v.systemTransport.user.userId.$model;
      if (!userId) {
        this.$message.error("กรุณาเลือกผู้ใช้");
      }
      if (this.$v.systemTransport.$anyError) {
        return;
      }

      const { systemTransport } = this.$v;
      const transportNumber = systemTransport.transportNumber.$model;
      const amount = systemTransport.amount.$model;
      const remark = systemTransport.remark.$model;
      const guarantee = systemTransport.guarantee.$model;
      const price_wooden_box = systemTransport.price_wooden_box.$model;
      const userLevelPrice = systemTransport.userLevelPrice.$model;
      const size = systemTransport.size.$model;
      const lotId = systemTransport.lotId.$model;
      const file_tracking = systemTransport.file_tracking.$model.map(
        x => x.path
      );
      const send_to_warehouse_china =
        systemTransport.timeline.send_to_warehouse_china.$model;
      const send_out_china = systemTransport.timeline.send_out_china.$model;
      const send_to_warehouse_th =
        systemTransport.timeline.send_to_warehouse_th.$model;

      size.weight = Number(size.weight);
      size.width = Number(size.width);
      size.long = Number(size.long);
      size.height = Number(size.height);

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const input = {
        lotId: this.lotId,
        transport: {
          _id: this.systemTransport._id,
          transportId: this.systemTransport.transportId,
          transportNumber: transportNumber,
          amount: Number(amount),
          remark,
          guarantee,
          price_wooden_box: Number(price_wooden_box) || 0,
          size: size,
          userId,
          lotId: Number(lotId) || 0,
          file_tracking,
          timeline: {
            send_to_warehouse_china: send_to_warehouse_china
              ? dayjs(send_to_warehouse_china)
              : "",
            send_out_china: send_out_china ? dayjs(send_out_china) : "",
            send_to_warehouse_th: send_to_warehouse_th
              ? dayjs(send_to_warehouse_th)
              : ""
          },
          userLevelPrice: {
            userLevelDetailName: userLevelPrice.userLevelDetailName,
            userLevelDetailPriceCube: userLevelPrice.userLevelDetailPriceCube,
            userLevelDetailPriceKg: userLevelPrice.userLevelDetailPriceKg,
            userLevelDetailShipping: userLevelPrice.userLevelDetailShipping
          }
        }
      };

      this.loadingSave = true;
      const {
        data: { add_transport_to_lot }
      } = await this.$apollo.mutate({
        mutation: ADD_LOT_TRACKING,
        variables: {
          input: input
        }
      });
      this.loadingSave = false;
      const { message, isSuccess } = add_transport_to_lot;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$nextTick(() => {
          this.$bvModal.hide("transport-modal");
        });
        if (this.onComplete) {
          this.onComplete();
        }
      }
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY HH:mm");
      }
    },
    searchUser(search, loading) {
      this.search = search;
      loading(true);
      this.searchDelay(loading, search, this);
    },
    searchDelay: debounce((loading, search, vm) => {
      vm.$apollo.queries.searchUserList.refetch({ search }).then(() => {
        loading(false);
      });
    }, 350),
    calculatePrice() {
      const { size, amount, userLevelPrice } = this.systemTransport;
      if (!size) {
        this.systemTransport.trackingPrice = {
          priceName: "kg",
          priceValue: 0
        };
        return;
      }
      const cube = calculateCube(size, amount);
      const weight = size ? size.weight || 0 : 0;
      const rateKg = userLevelPrice
        ? userLevelPrice.userLevelDetailPriceKg || 0
        : 0;
      const rateCube = userLevelPrice
        ? userLevelPrice.userLevelDetailPriceCube || 0
        : 0;

      const priceKg = bigDecimal.multiply(rateKg, weight) * amount;
      const priceCube = bigDecimal.multiply(rateCube, cube) * amount;

      if (priceCube > priceKg) {
        this.systemTransport.trackingPrice = {
          priceName: "cube",
          priceValue: priceCube
        };
        return;
      }
      this.systemTransport.trackingPrice = {
        priceName: "kg",
        priceValue: priceKg
      };
    },
    uploadImageSuccess(formData) {
      this.loadingImageList = true;
      axios
        .post(`${API_URL}/upload/temp2`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => {
          const { data } = res;
          this.loadingImageList = false;
          if (data) {
            for (
              let i = 0;
              i < this.systemTransport.file_tracking.length;
              i++
            ) {
              const image = this.systemTransport.file_tracking[i];
              image.highlight = 0;
            }
            this.systemTransport.file_tracking.push({
              path: `${IMAGE_URL}${data.data}`,
              default: this.systemTransport.file_tracking.length === 0 ? 1 : 0,
              highlight: 1
            });
          }
        });
    },
    uploadFunction(e) {
      let file = e.target.files;
      if (file && file[0]) {
        if (
          file[0]["type"] !== "image/jpeg" &&
          file[0]["type"] !== "image/png"
        ) {
          Swal.fire({
            title: "",
            text: "แนบไฟล์ JPG/PNG เท่านั้น",
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
          return;
        }
        this.loadingImage = true;
        this.upload(file[0], event => {
          this.progress = Math.round((100 * event.loaded) / event.total);
        }).then(res => {
          this.loadingImage = false;
          const { data } = res;
          if (data) {
            document.getElementById("getFile").value = "";
            const urlImage = data.data;
            let quill = this.$refs.myQuillEditor.quill;
            let length = quill.getSelection().index; // Get the cursor position
            quill.insertEmbed(length, "image", urlImage); // Insert image res.url for the image address returned by the server
            quill.setSelection(length + 1); // adjust the cursor to the end
          }
        });
      }
    },
    upload(file, onUploadProgress) {
      let formData = new FormData();
      formData.append("file", file);

      return axios.post(`${API_URL}/upload/cloud`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress
      });
    },
    beforeRemove(index, done) {
      done();
      if (this.systemTransport.file_tracking[index].default) {
        this.systemTransport.file_tracking.splice(index, 1);
        if (this.systemTransport.file_tracking.length > 0) {
          this.systemTransport.file_tracking[0].default = 1;
        }
      } else {
        this.systemTransport.file_tracking.splice(index, 1);
      }
      if (this.systemTransport.file_tracking[index]) {
        this.systemTransport.file_tracking[index].highlight = 1;
      }
    }
  },
  apollo: {
    searchUserList: {
      query: SEARCH_USERS,
      variables() {
        return {
          search: this.search
        };
      }
    },
    systemUserLevel: {
      query: GET_USER_LEVEL,
      skip() {
        return (
          !this ||
          !this.systemTransport ||
          !this.systemTransport.user ||
          !this.systemTransport.user.userId
        );
      },
      variables() {
        return {
          userId:
            this.systemTransport &&
            this.systemTransport.user &&
            this.systemTransport.user.userId
        };
      },
      update: ({ systemUserLevel }) => {
        if (!systemUserLevel) {
          return {
            shippingShip: [],
            shippingEk: []
          };
        }
        systemUserLevel.shippingEk = (systemUserLevel.shippingEk || []).map(
          x => ({
            text: x.userLevelDetailName,
            value: {
              userLevelDetailName: x.userLevelDetailName,
              userLevelDetailPriceCube: x.userLevelDetailPriceCube,
              userLevelDetailPriceKg: x.userLevelDetailPriceKg,
              userLevelDetailShipping: x.userLevelDetailShipping
            }
          })
        );
        systemUserLevel.shippingShip = (systemUserLevel.shippingShip || []).map(
          x => ({
            text: x.userLevelDetailName,
            value: {
              userLevelDetailName: x.userLevelDetailName,
              userLevelDetailPriceCube: x.userLevelDetailPriceCube,
              userLevelDetailPriceKg: x.userLevelDetailPriceKg,
              userLevelDetailShipping: x.userLevelDetailShipping
            }
          })
        );
        return systemUserLevel;
      }
    }
  },
  watch: {
    transport: function(val) {
      if (val) {
        this.systemTransport = val;
      } else {
        this.systemTransport = {
          transportNumber: this.systemTransport.transportNumber,
          user: null,
          amount: 0,
          ship_by: "ek",
          remark: "",
          guarantee: false,
          userLevelPrice: "",
          size: {
            weight: 0,
            width: 0,
            long: 0,
            height: 0
          },
          file_tracking: [],
          timeline: {},
          trackingPrice: {}
        };
      }
    },
    ["systemTransport.userLevelPrice"]: function() {
      this.calculatePrice();
    },
    ["systemTransport.amount"]: function() {
      this.calculatePrice();
    },
    ["systemTransport.size.weight"]: function() {
      this.calculatePrice();
    },
    ["systemTransport.size.width"]: function() {
      this.calculatePrice();
    },
    ["systemTransport.size.long"]: function() {
      this.calculatePrice();
    },
    ["systemTransport.size.height"]: function() {
      this.calculatePrice();
    }
  }
};
</script>
