<template>
  <div v-frag>
    <b-overlay :show="$apollo.queries.systemLot.loading" rounded="sm">
      <b-form ref="form" @submit.prevent="save">
        <div class="card" v-if="systemLot">
          <div class="card-header">
            <h3 class="mb-0">แก้ไขล็อต {{ systemLot.lotId }}</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-10">
                <div class="form-group row mb-2">
                  <label
                    for="input-transportNumber"
                    class="col-form-label text-lg-right col-md-3 col-sm-12"
                  >
                    ชื่อล็อต
                  </label>
                  <div class="col-lg-6 col-md-9 col-sm-12">
                    <b-form-input
                      id="input-lotName"
                      v-model="systemLot.lotName"
                      :state="validateState('lotName')"
                      required
                      placeholder=""
                    ></b-form-input>
                  </div>
                </div>
                <div class="form-group row mb-2">
                  <label
                    for="input-ship_by"
                    class="col-form-label text-lg-right col-md-3 col-sm-12"
                    >เส้นทาง</label
                  >
                  <div class="col-lg-6 col-md-9 col-sm-12">
                    <b-form-select
                      v-model="systemLot.type"
                      :state="validateState('type')"
                      required
                      :options="typeList"
                    ></b-form-select>
                  </div>
                </div>
                <div class="form-group row mb-2">
                  <label
                    for="input-ship_by"
                    class="col-form-label text-lg-right col-md-3 col-sm-12"
                    >เลือกขนส่งระหว่างประเทศ</label
                  >
                  <div class="col-lg-6 col-md-9 col-sm-12">
                    <b-dropdown
                      v-model="systemLot.ship_by"
                      :state="validateState('ship_by')"
                      :text="
                        systemLot.ship_by === 'ek'
                          ? 'ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)'
                          : 'ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน）'
                      "
                    >
                      <b-dropdown-item
                        value="ek"
                        @click="systemLot.ship_by = 'ek'"
                      >
                        ขนส่งทางรถ (ใช้เวลาประมาณ 5-7 วัน)
                      </b-dropdown-item>
                      <b-dropdown-item
                        value="ship"
                        @click="systemLot.ship_by = 'ship'"
                      >
                        ขนส่งทางเรือ (ใช้เวลาประมาณ 15-18 วัน)
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <div class="form-group row mb-2">
                  <label
                    for="input-ship_by"
                    class="col-form-label text-lg-right col-md-3 col-sm-12"
                    >เข้าโกดัง</label
                  >
                  <div class="col-lg-5 col-sm-5 col-12 notranslate">
                    <b-input-group>
                      <b-form-datepicker
                        type="date"
                        placeholder="ยังไม่เลือกวันที่"
                        locale="th-TH"
                        label-no-date-selected=""
                        v-model="systemLot.send_to_warehouse_china"
                        reset-button
                      />
                      <template v-slot:append>
                        <b-button
                          @click="systemLot.send_to_warehouse_china = null"
                        >
                          <inline-svg
                            src="/media/svg/icons/Navigation/Close.svg"
                          />
                        </b-button>
                      </template>
                    </b-input-group>
                  </div>
                </div>
                <div class="form-group row mb-2">
                  <label
                    for="input-ship_by"
                    class="col-form-label text-lg-right col-md-3 col-sm-12"
                    >ออกโกดัง</label
                  >
                  <div class="col-lg-5 col-sm-5 col-12 notranslate">
                    <b-input-group>
                      <b-form-datepicker
                        type="date"
                        placeholder="ยังไม่เลือกวันที่"
                        locale="th-TH"
                        label-no-date-selected=""
                        v-model="systemLot.send_out_china"
                        reset-button
                      />
                      <template v-slot:append>
                        <b-button @click="systemLot.send_out_china = null">
                          <inline-svg
                            src="/media/svg/icons/Navigation/Close.svg"
                          />
                        </b-button>
                      </template>
                    </b-input-group>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    for="input-ship_by"
                    class="col-form-label text-lg-right col-md-3 col-sm-12"
                    >ถึงโกดังปลายทาง</label
                  >
                  <div class="col-lg-5 col-sm-5 col-12 notranslate">
                    <b-input-group>
                      <b-form-datepicker
                        type="date"
                        placeholder="ยังไม่เลือกวันที่"
                        locale="th-TH"
                        label-no-date-selected=""
                        v-model="systemLot.send_to_warehouse_th"
                        reset-button
                      />
                      <template v-slot:append>
                        <b-button
                          @click="systemLot.send_to_warehouse_th = null"
                        >
                          <inline-svg
                            src="/media/svg/icons/Navigation/Close.svg"
                          />
                        </b-button>
                      </template>
                    </b-input-group>
                  </div>
                </div>
                <div class="form-group row">
                  <label
                    for="input-lotUrl"
                    class="col-form-label text-right col-md-3 col-sm-12"
                  >
                    ลิงค์ Google Drive
                  </label>
                  <div class="col-lg-6 col-md-9 col-sm-12">
                    <b-form-textarea
                      id="input-lotUrl"
                      v-model="systemLot.lotUrl"
                      :state="validateState('lotUrl')"
                      rows="8"
                      placeholder=""
                    ></b-form-textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-10 ml-lg-auto">
                <button
                  type="submit"
                  class="btn btn-success mr-4"
                  style="min-width: 140px"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg src="/media/svg/icons/General/Save.svg" />
                  </span>
                  บันทึก
                </button>
                <router-link
                  to="/admin/lot"
                  class="btn btn-secondary"
                  style="width: 140px"
                >
                  ยกเลิก
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-form>
    </b-overlay>
    <div class="card card-custom mt-4" v-if="systemLot">
      <div class="card-header">
        <div class="card-title">
          <h3 class="mb-0">รหัสพัสดุ</h3>
        </div>
        <div class="card-toolbar">
          <button
            @click.exact="openModal()"
            @click.prevent=""
            class="btn btn-primary font-weight-bolder"
          >
            <span class="svg-icon svg-icon-md svg-icon-white">
              <inline-svg src="/media/svg/icons/Navigation/Plus.svg" />
            </span>
            รับเข้าโกดัง
          </button>
        </div>
      </div>
      <div class="table-responsive">
        <table
          class="table table-head-solid table-bordered table-sm table-vertical-center"
        >
          <thead>
            <tr>
              <th class="text-center px-3">#</th>
              <th style="width: 50px">รหัสพัสดุ</th>
              <th class="text-center">ผู้ใช้งาน</th>
              <th class="text-center">ประเภทสินค้า</th>
              <th class="text-center">ตีลัง</th>
              <th class="text-center">จำนวน</th>
              <th class="text-center">KG</th>
              <th class="text-center">ขนาด</th>
              <th class="text-center">เข้าโกดัง</th>
              <th class="text-center">ออกโกดัง</th>
              <th class="text-center">ถึงโกดังปลายทาง</th>
              <th class="text-center">ราคา</th>
              <th class="text-center">รูป</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <template v-for="(item, i) in systemLot.transportList">
              <template>
                <tr :key="item._id">
                  <td class="text-center">
                    {{ systemLot.transportList.length - i }}
                  </td>
                  <td>{{ item.transportNumber }}</td>
                  <td class="text-center">
                    <router-link
                      :to="`/admin/users/${item.userId}`"
                      target="_blank"
                    >
                      {{ item.user ? item.user.username : "-" }}
                    </router-link>
                  </td>
                  <td class="text-center">
                    {{
                      item.userLevelPrice
                        ? item.userLevelPrice.userLevelDetailName
                        : "-"
                    }}
                  </td>
                  <td class="text-center">
                    <span class="text-success" v-if="item.guarantee">
                      ตีลัง
                    </span>
                  </td>
                  <td class="text-center">
                    {{ item.amount }}
                  </td>
                  <td class="text-center">
                    {{
                      calculateWeight(
                        item.size && item.size.weight,
                        item.amount
                      ) || "-"
                    }}
                  </td>
                  <td class="text-nowrap text-center">
                    {{
                      `${item.size.width || 0} x ${item.size.long || 0} x ${item
                        .size.height || 0}`
                    }}
                  </td>
                  <td class="text-center">
                    {{ format_date(item.timeline.send_to_warehouse_china) }}
                  </td>
                  <td class="text-center">
                    {{ format_date(item.timeline.send_out_china) }}
                  </td>
                  <td class="text-center">
                    {{ format_date(item.timeline.send_to_warehouse_th) }}
                  </td>
                  <td class="text-right">
                    {{
                      item.trackingPrice ? item.trackingPrice.priceValue : "-"
                    }}
                    <small>
                      {{
                        item.trackingPrice && item.trackingPrice.priceName
                          ? `(ราคา ${
                              item.trackingPrice.priceName === "cube"
                                ? "CBM"
                                : "KG"
                            })`
                          : ""
                      }}
                    </small>
                  </td>
                  <td class="text-center">
                    <div class="symbol symbol-20 flex-shrink-0">
                      <img
                        v-if="item.file_tracking && item.file_tracking[0]"
                        :src="imageUrlApi(item.file_tracking[0])"
                        style="object-fit: cover"
                        class="symbol-label"
                        v-viewer
                      />
                    </div>
                  </td>
                  <td class="text-center">
                    <b-dropdown size="sm" no-caret toggle-class="p-1">
                      <template #button-content>
                        <i
                          class="la la-ellipsis-h"
                          style="padding-right: 0px"
                        />
                      </template>
                      <b-dropdown-item @click="editTransport(item.transportId)">
                        <i class="la la-pencil-alt mr-2" />
                        <span class="menu-text">แก้ไข</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="remove_tracking(item)">
                        <i class="la la-trash mr-2" />
                        <span class="menu-text">ลบ</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <modal-transport
      v-if="systemLot"
      :transport="transport"
      :onBlur="onBlur"
      title="รับเข้าโกดัง"
      :lotId="systemLot.lotId"
      :onComplete="onCompleteModal"
    />
  </div>
</template>
<script>
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import {
  UPDATE_LOT,
  GET_LOT,
  GET_FIND_TRACKINGS,
  REMOVE_LOT_TRACKING
} from "@/graphql/admin/lot";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { WEBSITE, calculateWeight, calculateCube, imageUrlApi } from "@/config";
import ModalTransport from "./components/ModalTransport.vue";

export default {
  name: "LotDetail",
  mixins: [validationMixin],
  created() {
    document.title = `แก้ไขล็อต | ${WEBSITE}`;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: `รายการล็อต`, route: "/admin/lot" },
      { title: `แก้ไขล็อต` }
    ]);
  },
  data() {
    const { id } = this.$route.params;
    return {
      id: Number(id),
      transportId: 0,
      transportNumber: "",
      isEdit: false,
      nameState: null,
      messageError: "",
      search: "",
      transport: null,
      typeList: [
        { text: "จีน - ไทย", value: "china-thai" },
        { text: "ไทย - ลาว", value: "thai-lao" }
      ],
      systemLot: {
        lotName: "",
        lotUrl: "",
        ship_by: "ek",
        type: "",
        send_to_warehouse_china: null,
        send_out_china: null,
        send_to_warehouse_th: null
      }
    };
  },
  components: {
    ModalTransport
  },
  validations: {
    systemLot: {
      lotName: {
        required
      },
      lotUrl: {},
      type: { required },
      ship_by: {
        required
      },
      send_to_warehouse_china: {},
      send_out_china: {},
      send_to_warehouse_th: {}
    }
  },
  methods: {
    imageUrlApi,
    onBlur(e) {
      this.transportId = 0;
      if (!this.isEdit) {
        this.transportNumber = e.target.value;
      }
    },
    async remove_tracking(item) {
      const confirm = await Swal.fire({
        title: `นำเข้าพัสดุ ${item.transportNumber} ออกจากล็อต?`,
        icon: "question",
        html: `ยืนยันการทำรายการ`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const input = {
        lotId: this.systemLot.lotId,
        transportId: item.transportId
      };

      const {
        data: { remove_transport_lot }
      } = await this.$apollo.mutate({
        mutation: REMOVE_LOT_TRACKING,
        variables: {
          ...input
        }
      });
      const { message, isSuccess } = remove_transport_lot;
      if (!isSuccess) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$apollo.queries.systemLot.refetch();
      }
    },
    openModal() {
      this.isEdit = false;
      this.transportId = 0;
      this.transportNumber = "";
      this.transport = {
        transportNumber: "",
        user: null,
        amount: 0,
        ship_by: "ek",
        remark: "",
        guarantee: false,
        userLevelPrice: "",
        file_tracking: [],
        size: {
          weight: 0,
          width: 0,
          long: 0,
          height: 0
        },
        timeline: {},
        trackingPrice: {}
      };
      this.$nextTick(() => {
        this.$bvModal.show("transport-modal");
      });
    },
    async editTransport(transportId) {
      this.transportId = transportId;
      this.isEdit = true;
      if (this.transportId === transportId) {
        this.$apollo.queries.findTransport.refetch();
      }
    },
    calculateWeight,
    calculateCube,
    onCompleteModal() {
      this.$apollo.queries.systemLot.refetch();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.systemLot[name];
      return $dirty ? !$error : null;
    },
    async save() {
      this.$v.systemLot.$touch();
      if (this.$v.systemLot.$anyError) {
        return;
      }
      const { systemLot } = this.$v;
      const lotName = systemLot.lotName.$model;
      const lotUrl = systemLot.lotUrl.$model;
      const ship_by = systemLot.ship_by.$model;
      const type = systemLot.type.$model;
      const send_to_warehouse_china = systemLot.send_to_warehouse_china.$model;
      const send_out_china = systemLot.send_out_china.$model;
      const send_to_warehouse_th = systemLot.send_to_warehouse_th.$model;

      const input = {
        _id: this.systemLot._id,
        lotName,
        lotUrl,
        type,
        ship_by,
        send_to_warehouse_china: send_to_warehouse_china,
        send_out_china: send_out_china,
        send_to_warehouse_th: send_to_warehouse_th
      };

      const confirm = await Swal.fire({
        title: "ยืนยันความถูกต้อง ?",
        icon: "question",
        html: `ยืนยันการดำเนินการใช่ หรือไม่`,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "OK",
        cancelButtonText: "Cancel"
      });
      if (!confirm.value) {
        return;
      }

      const {
        data: { update_lot }
      } = await this.$apollo.mutate({
        mutation: UPDATE_LOT,
        variables: {
          input: input
        }
      });
      const { message, is_error } = update_lot;
      if (is_error) {
        this.$message.error(message);
      } else {
        this.$message.success(message);
        this.$apollo.queries.systemLot.refetch();
      }
    },
    format_date(value) {
      if (value) {
        return dayjs(String(value)).format("DD/MM/YYYY");
      }
      return "-";
    }
  },
  apollo: {
    systemLot: {
      query: GET_LOT,
      variables() {
        return {
          id: this.id
        };
      },
      update: ({ systemLot }) => {
        if (!systemLot) {
          return systemLot;
        }
        const { record } = systemLot;
        return record;
      }
    },
    findTransport: {
      query: GET_FIND_TRACKINGS,
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      skip() {
        return !this.transportId && !this.transportNumber;
      },
      variables() {
        return {
          transportId: this.transportId,
          transportNumber: this.transportNumber
        };
      },
      result({ loading, data }) {
        if (!loading && data) {
          const { findTransport } = data;
          const val = findTransport.data;
          if (!val.file_tracking) {
            val.file_tracking = [];
          }
          this.transport = { ...findTransport.data };
          this.transport.file_tracking = val.file_tracking.map((x, i) => ({
            path: imageUrlApi(x),
            default: i === 0 ? 1 : 0,
            highlight: i === 0 ? 1 : 0
          }));
          this.$nextTick(() => {
            this.$bvModal.show("transport-modal");
          });
        }
      }
    }
  }
};
</script>
